<div class="dialog-container">
  <p>{{dialogConfig.data.body}}</p>
</div>

<form class="form-dialog">
  <fieldset class="form-footer text-right">  
    <p-button
      [label]="dialogConfig.data.no"
      styleClass="btn-sd btn-md btn-ghost btn-ghost-green p-button p-component p-ripple"
      (onClick)="close(false)"
      ></p-button>
    <p-button
      [label]="dialogConfig.data.yes"
      styleClass="btn-sd btn-md btn-green"
      (onClick)="close(true)"
      ></p-button>
  </fieldset>
</form>
