import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { DialogOnboardingComponent } from './dialog-onboarding/dialog-onboarding.component';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { ChecklistOnboardingComponent } from './checklist-onboarding/checklist-onboarding.component';
import { TimelineModule } from 'primeng/timeline';
import { DialogService } from 'primeng/dynamicdialog';

@NgModule({
  declarations: [
    DialogOnboardingComponent,
    ChecklistOnboardingComponent
  ],
  exports: [
    ChecklistOnboardingComponent
  ],
  imports: [
    CommonModule,
    CheckboxModule,
    ButtonModule,
    SwiperModule,
    TimelineModule
  ],
  providers: [
    DialogService
  ]
})
export class OnboardingModule { }
