import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChecklistService {

  private checklistUpdateSubject = new Subject<null>();

  checklistUpdate = this.checklistUpdateSubject.asObservable();

  constructor() { }

  updateChecklist() {
    this.checklistUpdateSubject.next();
  }
}
