import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthTokenInterceptor } from './interceptors/auth.token.interceptor';
import { ApiErrorInterceptor } from './interceptors/api.error.interceptor';

import { ToastModule } from 'primeng/toast';
import { ProgressBarModule } from 'primeng/progressbar';

import { AppComponent } from './app.component';
import { PageLoadingComponent } from 'src/app/shared/page-loading/page-loading.component';
import { ToastMessageComponent } from 'src/app/shared/toast-message/toast-message.component';
import { MessageService } from 'primeng/api';

import { ErrorHandler } from '@angular/core';
import { rollbarFactory, RollbarService } from './services/rollbar.config';
import { ErrorLoggerService } from './services/error-logger.service';

import { NgxStripeModule } from 'ngx-stripe';
import { OnboardingModule } from './onboarding/onboarding.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'timer',
    pathMatch: 'full'
  },
  {
    path: 'invites',
    loadChildren: () => import('./invite/invite.module').then(m => m.InviteModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  { 
    path: 'share', 
    loadChildren: () => import('./public-report/public-report.module').then(m => m.PublicReportModule) 
  },
  {
    path: 'google-calendar',
    loadChildren: () => import('src/app/integrations/google-calendar/google-calendar.module').then(m => m.GoogleCalendarIntegrationModule)
  },
  {
    path: '',
    loadChildren: () => import('./app-main/app-main.module').then(m => m.AppMainModule)
  }
];

@NgModule({
  declarations: [
    AppComponent,
    PageLoadingComponent,
    ToastMessageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    ProgressBarModule,
    NgxStripeModule.forRoot(),
    OnboardingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiErrorInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorLoggerService,
    },
    { 
      provide: RollbarService, 
      useFactory: rollbarFactory 
    },
    MessageService,
    // useHttpCacheLocalStorage
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
