import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ToastMessageService } from 'src/app/services/toast-message.service';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss']
})
export class ToastMessageComponent implements OnInit {

  constructor(
    private messageService: MessageService,
    private toastMessageService: ToastMessageService
  ) { }

  toastSubscription!: Subscription;

  ngOnInit(): void {
    
    //show toast message received from subject
    this.toastSubscription = this.toastMessageService.showToast
      .subscribe(message => {
        this.messageService.add(message);
      });

  }

  ngOnDestroy() {
    this.toastSubscription?.unsubscribe();
  }
  
}
