import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogGenericConfirmationComponent } from '../shared/dialog-generic-confirmation/dialog-generic-confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class GenericDialogService {

  constructor(
    private dialogService: DialogService
  ) { }

  open(config: {title: string, body: string, yes?: string, no?: string}): DynamicDialogRef {
    if(!config.yes) {
      config.yes = "Yes";
    }
    if(!config.no) {
      config.no = "No";
    }

    return this.dialogService.open(DialogGenericConfirmationComponent, {
      header: config.title,
      styleClass: 'sd-dialog inc-subtext',
      data: {
        body: config.body,
        yes: config.yes,
        no: config.no
      }
    });
  }
}
