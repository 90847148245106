import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-page-loading',
  templateUrl: './page-loading.component.html',
  styleUrls: ['./page-loading.component.scss']
})
export class PageLoadingComponent implements OnInit {

  constructor(
    private loadingService: LoadingService
  ) { }
  
  loading: boolean = false;
  loadingSubscription!: Subscription;
  
  ngOnInit(): void {
    
    //show/hide loading based on subject state
    this.loadingSubscription = this.loadingService.showLoading
      .subscribe(state => {
        this.loading = state;
      });

  }

  ngOnDestroy() {
    this.loadingSubscription?.unsubscribe();
  }

}
