import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';

declare global {
  interface Window { 
    mixpanel: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  constructor() { }

  track(eventName: string, additionalProperties = {}) {

    let user: User = JSON.parse( localStorage.getItem('user') || '{}' );

    if(user?.id) {
      //track event with additional user data

      if(environment.enableMixpanelTracking) {
        window.mixpanel.track(eventName, {
          'workspace_id': user.active_workspace_id,
          ...additionalProperties
        });
      } else {
        //output in console for better visibility in dev environment
        console.info('%cMixpanel Event', 'color: orange', eventName, additionalProperties);
      }

    } else {
      //track event without additional user data
      
      if(environment.enableMixpanelTracking) {
        window.mixpanel.track(eventName, additionalProperties);
      } else {
        //output in console for better visibility in dev environment
        console.info('%cMixpanel Event', 'color: orange', eventName, additionalProperties);
      }

    }

  }

}
