import { Injectable } from '@angular/core';
import { DialogEditProfileComponent } from '../myaccount/dialog-edit-profile/dialog-edit-profile.component';
import { DialogService } from 'primeng/dynamicdialog';
import { User } from '../models/user';
import { DialogOnboardingComponent } from '../onboarding/dialog-onboarding/dialog-onboarding.component';
import { UserService } from './user.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  constructor(
    private userService: UserService,
  ) {}

  user!: User;
  dialogService!: DialogService;

  forceOnboarding: boolean = false;

  private tooltipProgressSubject = new Subject<null>();

  tooltipProgress = this.tooltipProgressSubject.asObservable();
  
  private openModalSubject = new Subject<null>();

  openModal = this.openModalSubject.asObservable();

  startOnboarding(name: 'Timer' | 'Project' | 'Google Calendar' | 'Dock', dialogService: DialogService) {

    this.dialogService = dialogService;
    this.user = JSON.parse( localStorage.getItem('user') || '{}' );

    if(this.forceOnboarding) {
      this.showOnboardingDialog(name);
      return;
    }

    if(this.user.first_name == 'First Name' || this.user.first_name == 'Last Name') {
      //show edit profile form, then onboarding steps

      const ref = this.dialogService.open(DialogEditProfileComponent, {
        // header: 'Let\'s track your first task!',
        showHeader: false,
        styleClass: 'sd-dialog dialog-onboarding',
        closeOnEscape: false,
        data: {
          onboardingName: name
        }
      });

    } else {
      //show onboarding steps

      if(name == 'Timer' && this.user.settings?.hide_onboarding == '0' 
        || name == 'Project' && this.user.settings?.hide_project_onboarding == '0' 
        || name == 'Google Calendar'
        || name == 'Dock') {
        if(name != 'Timer' || name == 'Timer' && !this.user.hasTrackedTime) {
          this.showOnboardingDialog(name);
        }
      }

    }

  }

  showOnboardingDialog(name: 'Timer' | 'Project' | 'Google Calendar' | 'Dock') {
    const ref = this.dialogService.open(DialogOnboardingComponent, {
      // header: 'Let\'s track your first task!',
      showHeader: false,
      styleClass: 'sd-dialog dialog-onboarding',
      closeOnEscape: false,
      data: {
        onboardingName: name
      }
    });
  }

  setUserSetting(setting: any) {
    const data = {
      'settings': setting
    };

    return this.userService.updateProfile(data);
  }

  progressTooltip() {
    this.tooltipProgressSubject.next();
  }

  triggerOpenModal() {
    this.openModalSubject.next();
  }

  // give overlayPanel a target so it stops printing errors
  createFakeTarget(thisElement: any) {
    return {
      offsetHeight: 0,
      parentNode: null,
      getBoundingClientRect: () => {
        return {
          top: thisElement
        }
      },
      contains: () => {
        return false;
      }
    }
  }
}
