<form class="sd-form form-dialog" [formGroup]="myForm" (ngSubmit)="save()">
  <div class="form-container">
    <fieldset class="form-input right">
      <h5>Profile Photo</h5>

      <div class="text-center">
        <p-avatar
          label="{{ user_name | initials }}"
          shape="circle"
          *ngIf="!user_avatar"
          styleClass="profile-avatar"
        ></p-avatar>
        <img class="img-preview" [src]="user_avatar | safe" alt="" *ngIf="user_avatar"/>
      </div>

      <div class="p-field img-upload">
        <p-fileUpload
          mode="basic"
          accept="image/*"
          [maxFileSize]="3000000"
          (onSelect)="getFile($event)"
          chooseLabel="Upload an image"
          class="sd-upload"
          styleClass="btn-sd btn-md btn-green"
        ></p-fileUpload>
      </div>
      <div class="remove-link">
        <a style="cursor: pointer;" (click)="removePhoto()" *ngIf="user_avatar">Remove photo</a>
      </div>
    </fieldset>
    <fieldset class="form-input left">
      <div class="p-field">
        <label>First name</label>
        <input
          type="text"
          pInputText
          formControlName="first_name"
          required
          autofocus
        />
      </div>
      <div class="p-field">
        <label>Last name</label>
        <input type="text" pInputText formControlName="last_name" required />
      </div>
      <div class="p-field" *ngIf="user.active_workspace_id">
        <label>What I do</label>
        <input type="text" pInputText formControlName="jobrole" />
        <p class="helper">Let people know what your role is</p>
      </div>
      <div class="p-field">
        <label>Location</label>
        <input type="text" pInputText formControlName="location" />
        <p class="helper">Let others know where you are based</p>
      </div>
      <div class="p-field">
        <label>Time zone</label>
        <p-dropdown
          [options]="timezones"
          optionLabel="name"
          optionValue="name"
          [filter]="true"
          formControlName="timezone"
          styleClass="fw"
          appendTo="body"
        ></p-dropdown>
        <p class="helper">Select the time zone based on your current location for accurate time tracking</p>
      </div>
    </fieldset>
  </div>
  <fieldset class="form-footer text-right">
    <p-button
      label="Cancel"
      styleClass="btn-sd btn-md btn-ghost btn-ghost-green"
      (onClick)="close()"
      [disabled]="loading"
    ></p-button>

    <p-button
      label="Save changes"
      styleClass="btn-sd btn-md btn-green"
      type="submit"
      [disabled]="loading || myForm.invalid"
    ></p-button>
  </fieldset>
</form>
