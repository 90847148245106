import { Injectable } from '@angular/core';
import { parse, format, isValid, isBefore, addDays, differenceInSeconds, parseISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  /**
   * Clean up the supplied date string and returns it in standard database format yyyy-MM-dd HH:mm:ss.
   */
  cleanDateStringToDatabaseFormat(date: string = '', returnType: 'Date' | 'string' = 'string'): Date | string {

    let currentTime: Date = new Date();
    let dateObj!: Date;
    let date_tester: Date;

    //replace dot with colon to standardize input
    date = date.replace('.', ':');

    if( isValid( date_tester = parse(date, "yyyy-MM-dd'T'HH:mm:ss'Z'", currentTime) ) ) {
      dateObj = date_tester;
    } else if( isValid( date_tester = parse(date, 'yyyy-MM-dd HH:mm:ss', currentTime) ) ) {
      dateObj = date_tester;
    } else if( isValid( date_tester = parse(date, 'yyyy-MM-dd h:mm a', currentTime) ) ) {
      dateObj = date_tester; 
    } else if( isValid( date_tester = parse(date, 'yyyy-MM-dd h:mma', currentTime) ) ) {
      dateObj = date_tester;
    } else if( isValid( date_tester = parse(date, 'yyyy-MM-dd hmma', currentTime) ) ) {
      dateObj = date_tester;
    } else if( isValid( date_tester = parse(date, 'yyyy-MM-dd HH:mm', currentTime) ) ) {
      dateObj = date_tester;
    } else if( isValid( date_tester = parse(date, 'yyyy-MM-dd HHmm', currentTime) ) ) {
      dateObj = date_tester;
    } else if( isValid( date_tester = parse(date, 'yyyy-MM-dd h a', currentTime) ) ) {
      dateObj = date_tester;
    } else if( isValid( date_tester = parse(date, 'yyyy-MM-dd ha', currentTime) ) ) {
      dateObj = date_tester;
    }

    if( isValid(dateObj) ) {
      
      if(returnType == 'Date') {
        return dateObj;
      } else {
        return format(dateObj, 'yyyy-MM-dd HH:mm:ss');
      }

    } else {
      return '';
    }

  }

  /**
   * Handles date strings processing and validation to calculate duration between start time and end time and returns the duration in seconds. Date format must be yyyy-MM-dd. Time format can be either 12H with AM/PM or 24H.
   * @param start_datetime Full date-time string. 
   * @param end_datetime Full date-time string. The end_datetime value cannot be lesser than start_datetime. If it is, the end_datetime value will be added by 1 day from start_datetime.
   * @returns Duration in seconds
   */
  calculateDuration(start_datetime: string, end_datetime: string): number {
    
    let start_date_time!: Date;
    let end_date_time!: Date;
    
    //validate and convert the strings to Date object
    start_date_time = <Date>this.cleanDateStringToDatabaseFormat(start_datetime, 'Date');
    end_date_time = <Date>this.cleanDateStringToDatabaseFormat(end_datetime, 'Date');

    //check if end_date_time is less than start_date_time. If yes, convert the end_date_time date to the next day because we consider the end_date_time is crossing a day.
    if( isBefore(end_date_time, start_date_time) ) {
      end_date_time = addDays(end_date_time, 1);
    }

    // console.log(start_date_time, end_date_time);

    return differenceInSeconds(end_date_time, start_date_time);

  }

  /**
   * Convert UTC date to user timezone.
   * @param date Date string in yyyy-MM-ddTHH:mm:ssZ format (ISO 8601).
   * @returns Date object or string in user timezone.
   */
  convertUtcToUserTimezone(date: string | number, returnType: 'Date' | 'string' = 'Date', formatStr: string = 'yyyy-MM-dd HH:mm:ss'): Date | string {
    
    let user: User = JSON.parse( localStorage.getItem('user') || '{}' );

    let timezone = 'UTC';

    if(user.timezone) {
      timezone = user.timezone;
    }

    let convertedDateTime = utcToZonedTime(date, timezone);

    if(returnType == 'Date') {
      return convertedDateTime;
    } else {
      return format(convertedDateTime, formatStr);
    }
    
  }

  /**
   * Convert UTC date to other timezone (doesn't rely on the User object).
   * @param date Date string in yyyy-MM-ddTHH:mm:ssZ format (ISO 8601).
   * @returns Date object or string in user timezone.
   */
   convertUtcToTimezone(date: string | number, toTimezone: string, returnType: 'Date' | 'string' = 'Date', formatStr: string = 'yyyy-MM-dd HH:mm:ss'): Date | string {

    let convertedDateTime = utcToZonedTime(date, toTimezone);

    if(returnType == 'Date') {
      return convertedDateTime;
    } else {
      return format(convertedDateTime, formatStr);
    }
    
  }

  /**
   * Convert user timezone to UTC.
   * @param date Date string in yyyy-MM-dd HH:mm:ss format (ISO 8601).
   * @returns Date string in yyyy-MM-ddTHH:mm:ssZ format (ISO 8601).
   */
   convertUserTimezoneToUtc(date: string | number): string {
    
    let user: User = JSON.parse( localStorage.getItem('user') || '{}' );

    let timezone = 'UTC';

    if(user.timezone) {
      timezone = user.timezone;
    }

    let convertedDateTime = zonedTimeToUtc(date, timezone);

    return convertedDateTime.toISOString();
    
  }

  /**
   * General purpose method to calculate the timestamp of the provided Date object or date string.
   * @param date Date object or date string.
   * @returns Timestamp in milliseconds.
   */
  convertLocalTimeToTimestamp(date: Date | string): number {
    if( isValid(date) ) {
      //Date
      return (<Date>date).getTime();
    } else {
      //string
      return new Date(<string>date).getTime()
    }
  }

  /**
   * Calculate the timestamp of the provided Date object as a UTC date. This differs than Date.getTime() or Date.now() in which the input date is treated as a UTC date as opposed to local date. Only needed for charting.
   * @param date Date object supposedly a UTC date.
   * @returns Timestamp in milliseconds since January 1, 1970, 00:00:00.
   */
   getUtcTimestamp(date: Date): number {

    return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());

  }

  /**
   * Calculate the timestamp at 12AM of the provided UTC Date object. This differs than Date.getTime() or Date.now() in which the input date is treated as a UTC date as opposed to local date. Only needed for charting.
   * @param date Date object supposedly a UTC date.
   * @returns Timestamp in milliseconds since January 1, 1970, 00:00:00.
   */
  getUtcStartOfDayTimestamp(date: Date): number {

    return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);

  }

  /**
   * Format a typical database-style UTC date from yyyy-MM-dd HH:mm:ss to ISO standard yyyy-MM-ddTHH:mm:ssZ.
   * @param date Date time string in UTC.
   * @returns ISO standard date time string in the format yyyy-MM-ddTHH:mm:ssZ.
   */
  formatUtcTimeToIso(date: string) {
    return format( parseISO(date), "yyyy-MM-dd'T'HH:mm:ss'Z'");
  }
  
}
