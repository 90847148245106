import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, ErrorHandler, Inject } from '@angular/core';
import { RollbarService } from 'src/app/services/rollbar.config';
import * as Rollbar from 'rollbar';

@Injectable({
  providedIn: 'root'
})
export class ErrorLoggerService implements ErrorHandler {

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar
  ) {}

  handleError(error: Error) {

    if(environment.rollbarAccessToken) {
      
      if (error instanceof HttpErrorResponse) {
        // the errors of this type are captured in api.error.interceptor.ts 
      } else {
        // console.log('errhandler internal', error);

        let user = JSON.parse( localStorage.getItem('user') || '{}' );
        
          const rollbarConfig: Rollbar.Configuration = {
            payload: {
              person: {
                id: user?.id,
                email: user?.email
              },
              custom: {
                active_workspace_id: user?.active_workspace_id,
                active_workspace_userlevel_id: user?.active_workspace_userlevel_id
              }
            }
          };

          this.rollbar.configure(rollbarConfig);
          
          //log this error into Rollbar
          this.rollbar.error(error);
      }
          
    } else {
      console.error(error);
    }
   
  }
}
