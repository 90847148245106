import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dialog-generic-confirmation',
  templateUrl: './dialog-generic-confirmation.component.html',
  styleUrls: ['./dialog-generic-confirmation.component.scss']
})
export class DialogGenericConfirmationComponent implements OnInit {

  constructor(
    public dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef
  ) { }

  ngOnInit(): void {
  }

  close(isYes: boolean) {
    this.dialogRef.close(isYes);
  }

}
