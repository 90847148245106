import { InjectionToken} from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment } from 'src/environments/environment';

const rollbarConfig: Rollbar.Configuration = {
   accessToken: environment.rollbarAccessToken,
   captureUncaught: true,
   captureUnhandledRejections: true,
   environment: environment.id
 };

 export function rollbarFactory() {
   return new Rollbar(rollbarConfig);
 }
 
 export const RollbarService = new InjectionToken<Rollbar>('rollbar');