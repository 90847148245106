import { Component, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { isSameDay } from 'date-fns';
import { Subscription, timer } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { User } from './models/user';
import { UserService } from './services/user.service';
import { environment } from 'src/environments/environment';
import { MixpanelService } from './services/mixpanel.service';
import { OneSignalService } from './services/onesignal.service';

declare global {
  interface Window { 
    smartsupp: any;
    mixpanel: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title: any;
  routerSubscription!: Subscription;
  timerSubscription!: Subscription;
  isLoggedIn: boolean = false;
  currentDate: Date = new Date();
  browserRefresh: boolean = true;
  
  constructor(
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private renderer2: Renderer2,
    private mixpanelService: MixpanelService,
    private oneSignalService: OneSignalService,
    @Inject(DOCUMENT) private _document: Document
  ) { }

  async ngOnInit() {

    const appTitle = this.titleService.getTitle();
    
    this.routerSubscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;

          while (child?.firstChild) {
            child = child.firstChild;
          }

          if (child?.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return '';
        })
      )
      .subscribe((title: string) => {
        //set page title dynamically
        this.titleService.setTitle(`${title} | ${appTitle}`);

        let user: User = JSON.parse( localStorage.getItem('user') || '{}' );

        if(user?.id) {
          this.isLoggedIn = true;
          
          //for Smartsupp feedback widget
          if(window.smartsupp) {
            window.smartsupp('name', `${user.first_name} ${user.last_name}`);
            window.smartsupp('email', user.email);
            window.smartsupp('variables', {
              User_ID: user.id,
              Active_workspace: `${user.active_workspace_id} - ${user.active_workspace_name}`
            });
          }

          //for Mixpanel tracking
          if(window.mixpanel) {
            
            window.mixpanel.identify(user.id);

            window.mixpanel.people.set({ 
              "$avatar": user.avatar,
              "$distinct_id": user.id,
              "$first_name": user.first_name,
              "$last_name": user.last_name,
              "$email": user.email,
              "$created": user.created_at,
              "Last Login": user.last_login,
              "Active Workspace": `${user.active_workspace_id} - ${user.active_workspace_name}` 
            });

          }

        } else {
          this.isLoggedIn = false;

          //for Mixpanel tracking
          if(window.mixpanel) {
            window.mixpanel.reset();
          }

        }

        if(this.browserRefresh) {
          //run these once when browser is refreshed manually

          if(user?.id) {

            //load Smartsupp in prod env
            if(environment.id == 'production') {
                
              let script = this.renderer2.createElement('script');
              script.id = 'smartsuppjs';
              script.type = 'text/javascript';
              script.async = true;
              script.defer = true;
              script.src = 'https://www.smartsuppchat.com/loader.js';

              this.renderer2.appendChild(this._document.body, script);

            }

            //load user data when browser is refreshed
            this.userService.getUserFullProfile()
              .subscribe(data => {

                setTimeout(() => {
                  
                  //identify user in Smartsupp
                  if(window.smartsupp) {
                    window.smartsupp('name', `${data.first_name} ${data.last_name}`);
                    window.smartsupp('email', data.email);
                    window.smartsupp('variables', {
                      User_ID: data.id,
                      Active_workspace: `${data.active_workspace_id} - ${data.active_workspace_name}`
                    });
                  }

                }, 3000);

              });

          }

          this.browserRefresh = false;

        }

        this.mixpanelService.track('Page View', { pageTitle: title });

      });
    
    //logic to force reload browser once a day to ensure any new updates can be seen by users
    this.timerSubscription = timer(0, 60000)
      .subscribe(minute => {

        let currentDate = new Date();

        if(!isSameDay(this.currentDate, currentDate)) {
          this.currentDate = currentDate;
          location.reload();
        }
        
      });

    //listen to changes in localStorage keys for token and user data.
    //The purpose is when the user has multiple tabs opened, when one tab is performing Log Out or Log In actions, the rest of the tabs will be redirected to the root page and Angular should be able to decide whether the user will see the Timer or the Login page.
    window.addEventListener('storage', (event) => {
      
      if (event.storageArea == localStorage) {

        if(event.key == 'token' || event.key == 'user') {
          let token = localStorage.getItem('token');
          let user = localStorage.getItem('user');

          if(token && user) {
            window.location.href = '/';
          }

          if(!token && !user) {
            window.location.href = '/';
          }
        }

      }
    }, false);
    
    //init OneSignal SDK
    await this.oneSignalService.initSdk();
    await this.oneSignalService.configureWebpushState();

  }

  ngOnDestroy() {
    this.routerSubscription?.unsubscribe();
    this.timerSubscription?.unsubscribe();
  }

}
