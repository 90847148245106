import { Injectable } from '@angular/core';
import { OneSignal } from 'onesignal-ngx';
import { environment } from 'src/environments/environment';
import { TimeTracking } from '../models/timeTracking';
import { User } from '../models/user';
import { PushNotificationHandlerService } from './push-notification-handler.service';

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {

  constructor(
    private oneSignal: OneSignal,
    private pushNotificationHandlerService: PushNotificationHandlerService
  ) {}
  
  /**
   * Initialise the OneSignal SDK if API keys are provided.
   */
  async initSdk() {

    if(environment.oneSignalAppId && environment.oneSignalAppId) {

      //Ref: https://documentation.onesignal.com/docs/web-push-sdk#init
      await this.oneSignal.init({
        appId: environment.oneSignalAppId,
        safari_web_id: environment.oneSignalAppId,
        notifyButton: {
          enable: false,
        },
        allowLocalhostAsSecureOrigin: environment.id  == 'local',   //special prop to allow onesignal to work in localhost for dev
      });

      //listen to notificationclick event from the service worker (for opened tabs)
      navigator.serviceWorker.onmessage = (event: MessageEvent) => {

        if(event?.data?.action && event?.data?.notification?.data) {
          this.pushNotificationHandlerService.handleAction(event?.data?.notification?.data?.payload);
        }

      }

    }

  }

  /**
   * Configure the web push notification state (activate/deactivate) based on the user settings. 
   */
  async configureWebpushState() {

    const user = JSON.parse( localStorage.getItem('user') || '{}' );

    //do nothing if OneSignal global object doesnt exist
    if(!window['OneSignal']) return;

    if(user?.settings) {
      //user logged in

      const permission = await this.checkPermission();
        
      if(this.isPushSupported() && user.settings.webpush.enabled == '1' && permission == 'granted') {
        //user enables web push in Toro notif settings and browser. So we send the user identity to OneSignal.
        
        console.log('playerid', await this.oneSignal.getUserId());

        await this.oneSignal.setExternalUserId( user.id.toString() );

      } else {
        //either push not supported in browser or user disables web push in Toro notif settings or has not set any permission in browser or explicitly denies permission in browser. So disconnect any existing user identity from OneSignal.
        console.log('push disabled');
        await this.oneSignal.removeExternalUserId();
      }

    } else {
      //user not logged in
      await this.oneSignal.removeExternalUserId();
    }

  }

  /**
   * Returns a Promise that resolves to the browser's current notification permission as 'default', 'granted', or 'denied'. You can use this to detect whether the user has allowed notifications, blocked notifications, or has not chosen either setting.
   */
  async checkPermission() {
    return this.oneSignal.getNotificationPermission()
  }

  /**
   * Show the native browser prompt for push notifications. If notification permissions have already been granted, nothing will happen.
   */
  async requestPermission() {
    return this.oneSignal.showNativePrompt()
  }

  /**
   * Returns true if the current browser environment viewing the page supports push notifications.
   */
  isPushSupported(): boolean {
    return 'PushManager' in window;
  }

  /** 
   * Test to check if the OneSignal window object has been initialised correctly.
   * This can return false when the SDK file is not loaded correctly via CDN such as when blocked by adblockers. When this returns false, other code on the component will stop executing (no idea why) so we need to handle it gracefully.
   */
  isOneSignalSdkInitialised(): boolean {
    return typeof window.OneSignal.isPushNotificationsEnabled === 'function'
  }

}
