import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { RollbarService } from 'src/app/services/rollbar.config';
import * as Rollbar from 'rollbar';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {

  private API_BASE = environment.apiUrl;
  
  constructor(
    private userService: UserService,
    private router: Router,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
    //get the bearer token to pass to Rollbar for easier troubleshooting. NOTE: just temporary, remove once app looks stable.
    let bearerToken = request.headers.get('authorization');

    if(bearerToken) {
      bearerToken = bearerToken.replace('Bearer', '');
    }
  
    return next.handle(request).
      pipe(
        tap(httpEvent => {

          if (httpEvent instanceof HttpResponse) {
            if(httpEvent.body?.status == 'NOT OK') {
              let user = JSON.parse( localStorage.getItem('user') || '{}' );

              if (Object.keys(user).length > 0) {
                //TODO: In the future all API calls should be appended with the latest user object so that we can always maintain the correct app state based on the latest user data.
                //refresh user profile in case we get any error caused by permission in order to update the UI based on the latest user
                this.userService.getUserFullProfile().subscribe();
              }

              if(environment.rollbarAccessToken) {

                let user = JSON.parse( localStorage.getItem('user') || '{}' );
                
                const rollbarConfig: Rollbar.Configuration = {
                  payload: {
                    person: {
                      id: user?.id,
                      email: user?.email
                    },
                    custom: {
                      active_workspace_id: user?.active_workspace_id,
                      active_workspace_userlevel_id: user?.active_workspace_userlevel_id
                    }
                  }
                };
    
                this.rollbar.configure(rollbarConfig);
                
                //set the base error message string
                let errorMsg: string = 'NOT OK API response';
                
                //try our best to get the real error message string
                if(typeof httpEvent.body?.message === 'string') {
                  errorMsg = httpEvent.body?.message;
                } else if(typeof httpEvent.body?.message?.error === 'string') {
                  errorMsg = httpEvent.body?.message?.error;
                } else if(typeof httpEvent.body?.message?.error?.message === 'string') {
                  errorMsg = httpEvent.body?.message?.error?.message;
                }

                //log this error into Rollbar
                this.rollbar.error(errorMsg, httpEvent.body?.message, {'authorization':bearerToken});

              }

            }
          }

        }),
        catchError((err: HttpErrorResponse) => {

          if(environment.rollbarAccessToken) {

            let user = JSON.parse( localStorage.getItem('user') || '{}' );
            
            const rollbarConfig: Rollbar.Configuration = {
              payload: {
                person: {
                  id: user?.id,
                  email: user?.email
                },
                custom: {
                  active_workspace_id: user?.active_workspace_id,
                  active_workspace_userlevel_id: user?.active_workspace_userlevel_id
                }
              }
            };

            this.rollbar.configure(rollbarConfig);

            //set the base error message string
            let errorMsg: string = 'ApiErrorInterceptor catchError';
                
            //try our best to get the real error message string
            if(typeof err === 'string') {
              errorMsg = err;
            } else if(typeof err?.message === 'string') {
              errorMsg = err?.message;
            } else if(typeof err?.error?.message === 'string') {
              errorMsg = err?.error?.message;
            }
            
            //log this error into Rollbar
            this.rollbar.error(errorMsg, err, {'authorization':bearerToken});

          }

          let message = (err.error?.message)? err.error.message : err.statusText;

          if(request.url.includes(this.API_BASE)) {

            if(err.status == 401) {
              return this.userService.logout()
                .pipe(
                  switchMap(() => {
                    window.location.href = '/';
                    return throwError(message);
                  })
                )
            }            
            
          }
          
          return throwError(message);

        })
      );

  }

}