<div class="checklist" *ngIf="user.settings.onboarding_checklist.hide_onboarding == '0' && !loading">
  <div class="checklist-head" 
    [ngClass]="{'collapsed' : minimized}"
    (click)="minimized = !minimized">
    <div class="chart">
      <div class="chart-desc">
      </div>
      <div 
        id="checklistDonutChart"
      ></div>
    </div>
    <div class="checklist-summary">
      <p>{{checksDone}} of {{maxChecks}} completed</p>
      <h6 *ngIf="minimized">{{ (checksDone >= maxChecks) ? 'Onboarding complete!' : 'Next: ' + checklistData[checksDone].title }} </h6>
    </div>
    <i class="arrow icon-down-open-1"></i>
  </div>
  <div class="checklist-content" *ngIf="!minimized">
    
    <ng-container *ngIf="checksDone < maxChecks">
      <h4>Welcome to Toro Timer!</h4>
      <h6>Let's get started with tracking your first task</h6>
    </ng-container>

    <ng-container *ngIf="checksDone >= maxChecks">
      <h4>Onboarding complete!</h4>
      <h6>You are now ready to take control of your productivity and achieve sustainable, healthier working habits. Happy thriving!</h6>
    </ng-container>

    <p-timeline class="timeline" [value]="checklistData" [ngClass]="{'checkDone': checksDone >= maxChecks}">
      
      <!-- marker -->
      <ng-template pTemplate="marker" let-data>
        <ng-container *ngIf="data.visible">
          <div class="innerDiv">
            <span [ngClass]="{'complete' : data.isComplete}">
              <i *ngIf="data.isComplete" class="icon-check"></i>
            </span>
          </div>
        </ng-container>
      </ng-template>

      <!-- content -->
      <ng-template pTemplate="content" let-data>
        <ng-container *ngIf="data.visible">
          <div class="innerDiv">
            <h5 *ngIf="checksDone >= maxChecks">{{data.title}}</h5>
            <a *ngIf="checksDone < maxChecks" (click)="data.clicked()">{{data.title}}</a>
            <p *ngIf="checksDone < maxChecks">{{data.instruction}}</p>
          </div>
        </ng-container>
      </ng-template>

    </p-timeline>
    <div class="checklist-footer">
      <button *ngIf="checksDone >= maxChecks" (click)="clickedHideOnboarding()" class="btn-sd btn-md btn-green">Close onboarding</button>
      <a *ngIf="checksDone < maxChecks" (click)="clickedHideOnboarding()">Skip onboarding</a>
    </div>
  </div>
</div>

